import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { A, AProps } from '@/components/atoms/A/A';
import { getGa4Linker } from '@/lib/gtm/getGa4Linker';
import { navLinkType, NavLinkTypeWithExternalLink } from '@/types/NavLinks';

interface Props extends AProps {
  link: NavLinkTypeWithExternalLink;
  children?: React.ReactNode;
}

export const NavLink = ({ link, children, ...rest }: Props) => {
  const { locale } = useRouter();

  if (link.type === navLinkType.external) {
    const onClick = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      const ga4Id = getGa4Linker();
      const urlObject = new URL(link.url!);

      urlObject.searchParams.set(...Object.entries(ga4Id)[0]);
      window.location.assign(urlObject.toString());
    };
    return (
      <A {...rest} onClick={onClick} href={link.url} rel="noreferrer nofollow">
        {children}
      </A>
    );
  } else if (link.type === navLinkType.local) {
    return (
      <Link href={link.url} passHref prefetch={false} legacyBehavior>
        <A {...rest}>{children}</A>
      </Link>
    );
  } else {
    let url = link.url;
    if (link.withLocale) {
      url = `/${locale}${link.url}`;
    }

    return (
      <A href={url} rel="noreferrer nofollow" target={link.target} {...rest}>
        {children}
      </A>
    );
  }
};
