import {
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
  Portal,
  Root,
} from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import cls from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styles from './Drawer.module.scss';

export interface DrawerProps {
  children: React.ReactNode;
  animateFrom?: 'left' | 'right';
  ariaLabel: string;
  className?: string;
  isOpen: boolean;
  onDismiss: () => void;
  onExitComplete?: () => void;
}

export const Drawer = ({
  animateFrom = 'right',
  ariaLabel = '',
  children,
  className,
  isOpen,
  onDismiss,
  onExitComplete,
  ...rest
}: DrawerProps) => {
  const translateFrom = animateFrom === 'right' ? '100%' : '-100%';
  return (
    <AnimatePresence onExitComplete={onExitComplete} initial={false}>
      {isOpen && (
        <Root open={isOpen} onOpenChange={onDismiss} modal>
          <Portal>
            <DialogOverlay>
              <motion.div
                {...rest}
                className={cls(styles.drawer, className)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 1 }}
              >
                <DialogContent>
                  <motion.div
                    className={styles.content}
                    data-dialog-content=""
                    initial={{ x: translateFrom }}
                    animate={{ x: 0 }}
                    exit={{ x: translateFrom }}
                    transition={{
                      bounceDamping: 9,
                    }}
                    style={animateFrom === 'right' ? { right: 0 } : { left: 0 }}
                  >
                    <VisuallyHidden>
                      <DialogTitle>{ariaLabel}</DialogTitle>
                      <DialogDescription>{ariaLabel}</DialogDescription>
                    </VisuallyHidden>
                    {children}
                  </motion.div>
                </DialogContent>
              </motion.div>
            </DialogOverlay>
          </Portal>
        </Root>
      )}
    </AnimatePresence>
  );
};
