import { HTMLAttributeAnchorTarget } from 'react';
import { Countries } from '@/types/Countries';

export const linkKey = {
  account: 'account',
  appStore: 'appStore',
  bikes: 'bikes',
  testRide: 'testRide',
  students: 'students',
  referral: 'referral',
  business: 'business',
  contact: 'contact',
  cookies: 'cookies',
  ebikes: 'ebikes',
  facebook: 'facebook',
  faq: 'faq',
  help: 'help',
  impressum: 'impressum',
  instagram: 'instagram',
  introWeeks: 'introWeeks',
  jobs: 'jobs',
  jobsDE: 'jobsDE',
  locations: 'locations',
  mentionslegales: 'mentionslegales',
  playStore: 'playStore',
  press: 'press',
  privacy: 'privacy',
  responsibleDisclosure: 'responsibleDisclosure',
  service: 'service',
  simpleTermination: 'simpleTermination',
  stores: 'stores',
  sustainability: 'sustainability',
  terms: 'terms',
  modernSlaveryStatement: 'modernSlaveryStatement',
  linkedIn: 'linkedIn',
  tiktok: 'tiktok',
} as const;

export type LinkKey = (typeof linkKey)[keyof typeof linkKey];

export const navLinkType = {
  custom: 'custom',
  external: 'external',
  local: 'local',
} as const;

export interface NavLinkLocal {
  url: string;
  key: string;
  type: typeof navLinkType.local;
}

interface NavLinkExternal {
  key: string;
  type: typeof navLinkType.external;
}

interface NavLinkCustom {
  key: string;
  target?: HTMLAttributeAnchorTarget;
  type: typeof navLinkType.custom;
  url: string;
  withLocale?: boolean;
}

interface NavLinkExternalWithUrl extends NavLinkExternal {
  url?: string;
}

export type NavLinkType =
  | NavLinkExternal
  | NavLinkLocal
  | NavLinkCustom
  | undefined;
export type NavLinkTypeWithExternalLink =
  | NavLinkExternalWithUrl
  | NavLinkLocal
  | NavLinkCustom;

export type NavLinksConfig = Partial<Record<LinkKey, NavLinkType>>;
export type NavLinksConfigWithExternalUrls = Record<
  LinkKey,
  NavLinkTypeWithExternalLink
>;

export type NavLinksData = Record<Countries, NavLinksConfig>;
export type NavLinksDataWithExternalLink = Record<
  Countries,
  NavLinksConfigWithExternalUrls
>;
