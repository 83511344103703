const getDate = (date: Date | string | undefined) =>
  date instanceof Date || date ? new Date(date) : new Date();

/**
 * Calculate a new date by adding or subtracting an offset amount
 * of days or hours to/from a given date.
 *
 * @param {Date|string|undefined} date - The base date.
 * @param {number} amount - The offset amount.
 * @param {'days' | 'hours'} unit - The unit of time: 'days' or 'hours'.
 * @returns {Date} The resulting date.
 */
export const getOffsetDate = (
  date: Date | string | undefined,
  amount: number,
  unit: 'days' | 'hours'
): Date => {
  const newDate = getDate(date);

  if (unit === 'days') {
    newDate.setDate(newDate.getDate() + amount);
  }
  if (unit === 'hours') {
    newDate.setHours(newDate.getHours() + amount);
  }

  return newDate;
};

/**
 * Format a Date object as a string in 'YYYY-MM-DD' format.
 *
 * @param {Date} date - The Date object to be formatted.
 * @returns {string} A string representing the date in 'YYYY-MM-DD' format.
 */
export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

/**
 * Get the number of days in a month for a given year.
 *
 * @param {number} year
 * @param {number} month - The month (zero-based, January is 0).
 * @returns {number} The number of days in the specified month and year.
 */
export const getDaysInMonth = (year: number, month: number): number => {
  const date = new Date(year, month + 1, 1);
  date.setDate(date.getDate() - 1);
  return date.getDate();
};

/**
 * Check if a date is before another date.
 *
 * @param {Date|string|undefined} currentDate - The current date.
 * @param {Date|string|undefined} dateToCompare - The date to compare.
 * @returns {boolean} `true` if `currentDate` is before `dateToCompare`, `false` otherwise.
 */
export const isBeforeDate = (
  currentDate: Date | string | undefined,
  dateToCompare: Date | string | undefined
): boolean => getDate(currentDate).getTime() < getDate(dateToCompare).getTime();

/**
 * Check if a date is after another date.
 *
 * @param {Date|string|undefined} currentDate - The current date.
 * @param {Date|string|undefined} dateToCompare - The date to compare.
 * @returns {boolean} `true` if `currentDate` is after `dateToCompare`, `false` otherwise.
 */
export const isAfterDate = (
  currentDate: Date | string | undefined,
  dateToCompare: Date | string | undefined
): boolean => getDate(currentDate).getTime() > getDate(dateToCompare).getTime();

/**
 * Transforms the date object into the date string,
 * e.g. '2000-01-01T12:00:00'
 *
 * @param {Date} date - Date to convert.
 * @returns {string} date string.
 */
export const toLocalISOString = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

/**
 * Gets HH:MM:SS from the date-time string
 * e.g. "2025-02-05T14:45:00+01:00" => "14:45:00"
 *
 * @param {string} dateTime - YYYY-MM-DDTHH:MM:SS+HH:MM date
 * @returns {string} HH:MM:SS time
 */
export const getTime = (dateTime: string) =>
  dateTime.match(/T(\d{2}:\d{2}:\d{2})/)?.[1] || '';
